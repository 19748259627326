import { graphql } from 'gatsby';
import React from 'react';
import tw from 'tailwind.macro';
import Button from '../../components/button';
import CollapsibleBox from '../../components/collapsibleBox';
import Grid from '../../components/grid';
import Layout from '../../components/layout';
import LeadText from '../../components/leadText';
import Masthead from '../../components/masthead';
import Section from '../../components/section';
import SEO from '../../components/seo';

const ProjectsEverydayJustice = ({ data, location }) => {
  const parentTitle = 'Projects';
  const pageTitle = 'Everyday Justice';

  return (
    <Layout location={location} parentTitle={parentTitle} pageTitle={pageTitle}>
      <SEO title={pageTitle} image={data.hero.childImageSharp.fluid.src} />
      <Masthead
        parentTitle={parentTitle}
        pageTitle={pageTitle}
        backgroundImage={data.hero.childImageSharp.fluid}
        styling={[
          tw`bg-maroon bg-right bg-repeat-x`,
          `min-height:40vh; background-size:50%;`,
        ]}
      />
      <Section>
        <h2>Overview</h2>
        <LeadText>Empowering you to make informed decisions.</LeadText>
        <p>
          We are dedicated to providing education to our community on questions
          pertaining to: (i) Maintenance and (ii) Gender Based Violence. We are
          your education resource, offering free workshops, to empower you to
          make informed decisions.
        </p>
        <Grid>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/biX1zIKGsVg?rel=0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            css={tw`max-w-full`}
          />
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/lXkgTCb-RR8?rel=0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            css={tw`max-w-full`}
          />
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/VcQ7-0mIDj8?rel=0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            css={tw`max-w-full`}
          />
        </Grid>
      </Section>
      <Section isShaded>
        <CollapsibleBox collapsibleByDefault>
          <h2>Overall objective</h2>
          <p>
            Our Everyday Justice project is a community education program,
            designed to promote learning and social development work with
            individuals and groups in their communities using a range of formal
            and informal methods. A common defining feature of our programme is
            that programmes and activities are developed in dialogue with
            communities and participants. The purpose of community learning and
            development is to develop the capacity of individuals and groups of
            all ages through their actions, the capacity of communities, to
            improve their quality of life. Central to this is the ability of
            individuals and a community to participate in democratic processes
            so as to ensure the attainment of social justice in their everyday
            lives.
          </p>
        </CollapsibleBox>
        <CollapsibleBox collapsibleByDefault>
          <h2>Goals</h2>
          <ul>
            <li>
              Law and justice change agents collaborating with communities
            </li>
            <li>Engaged student and community tuition</li>
          </ul>
          <h2>Expected outcomes</h2>
          <ul>
            <li>
              Community participation in democratic processes so as to ensure
              the attainment of social justice in their everyday lives
            </li>
            <li>
              An understanding of constitutional rights pertaining to
              maintenance issues and gender based violence
            </li>
          </ul>
        </CollapsibleBox>
      </Section>
      <Section>
        <h2>Get started</h2>
        <p>Contact us for further information and to arrange a workshop.</p>
        <Button to="/contact/">Contact us &rarr;</Button>
      </Section>
    </Layout>
  );
};

export default ProjectsEverydayJustice;

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "projects-mplan-hero.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
